import React,{useState} from 'react';
import './App.css';
import logo from './logo_trans_edit.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import Alert from '@mui/material/Alert';
import { Icon } from '@mui/material';
const Confirm = () => {
    return (
      <Alert severity="success" className='bg-slate-300 relative' style={{bottom:'100px'}}>
       
        Copied
      </Alert>
    );
  };
  
export const Footer = () => {
    let Info = {
      email: 'amsozzer@gmail.com',
      phone: '312-686-4511',
      linkedIn: 'https://www.linkedin.com/in/amsozzer1/',
      gitHub: 'https://github.com/amsozzer1',
      instagram: 'https://www.instagram.com/amsozzer/'
    };
    function NewTab(LINK) {
      window.open(
      LINK, "_blank");
  }
    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = (text) => {
      // New Clipboard API
      if (navigator.clipboard && window.isSecureContext) {
        // If Clipboard API is available
        navigator.clipboard.writeText(text).then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }, (err) => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // Fallback method for older browsers or non-secure contexts
        try {
          let textarea = document.createElement("textarea");
          textarea.value = text;
          // Make the textarea not visible
          textarea.style.position = "fixed"; // Prevents scrolling to the bottom
          document.body.appendChild(textarea);
          textarea.focus();
          textarea.select();
    
          document.execCommand('copy'); // Copies the text to clipboard
          document.body.removeChild(textarea);
    
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      }
    };
    return(
      <div className="flex flex-col items-center  pb-10 h-fit" id="reachOut" style={{backgroundColor: '#E9E1D6'}}>
        <div className="flex flex-col items-center">
          <div className='h-28 w-28 bg-body-color'>
            <img src={logo} alt="AMSOZZER" className="h-28 w-28 overflow-hidden relative" style={{right:'2px'}}/>
          </div>
          <p className="text-black text-center pt-5 w-96" style={{fontSize:'20px',textShadow: '2px 2px 4px rgba(0,0,0,0.1)', fontWeight:'bold'}}>Growing, advancing, and embracing life moment by moment.</p>
        </div>
        <div className="flex flex-row items-center justify-center pt-5">
          <button><Icon sx={{height:'3rem'}} className='m-2' onClick={() => copyToClipboard(Info.email)}><EmailIcon/></Icon></button>
          <button><Icon sx={{height:'3rem'}} className='m-2' onClick={() => copyToClipboard(Info.phone)}><PhoneIcon/></Icon></button>          
          <button><Icon sx={{height:'3rem'}} className='m-2' onClick={() => NewTab(Info.instagram)}><InstagramIcon/></Icon></button>
          <button><Icon sx={{height:'3rem'}} className='m-2' onClick={() => NewTab(Info.linkedIn)}><LinkedInIcon/></Icon></button>
          <button><Icon sx={{height:'3rem'}} className='m-2' onClick={() => NewTab(Info.gitHub)}><GitHubIcon/></Icon></button>
        </div>
        {copied && <Confirm />}
      </div>
    );
  };
  