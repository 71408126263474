import './App.css';
import { LandingPage } from './landing_page';
import { BLOGS } from './blogs/BLOGS';
import {createBrowserRouter,RouterProvider} from "react-router-dom";
const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage/>,
  },
  {
    path: '/blogs/:id',
    element: <BLOGS/>
  },
  {
    path:'*',
    element: <LandingPage/>
  }
]);
function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
