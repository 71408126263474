import logo from './logo_trans.png';
import scrollToId from './Scroll';
import {useNavigate} from 'react-router-dom';

export function Menubar(){
  let route = useNavigate();
  
  return (
    <div className=" w-100 h-28 flex flex-row items-center justify-between mx-16">
      <img src={logo} alt="AMSOZZER" className="h-28 w-28" onClick={()=>{
        route('/')
      }}/>
      <div className="flex flex-col items-center justify-center">
      <button onClick={() => scrollToId('reachOut')} className='rounded-full py-3 px-6 mr-5 text-xl border-solid border-2 pl-10 pr-10 text-center ' style={{ color: 'white', borderColor: 'white', fontWeight: 'bold' }}>Contact</button>
        </div>
    </div>
  );
    }