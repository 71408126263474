

export function AboutMe(){
    return(
        <div className="w-100 bg-container hello" style={{
            backgroundColor: '#E9E1D6',
            fontSize: '1vw',
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '2rem',
            
          }}>
            <div className="text-center" style={{
              color: '#494949',
              maxWidth: '800px', // This ensures the text doesn't span too wide
              padding: '2rem', // This gives some breathing room inside the container
              backgroundColor: '#ffffffaa', // A white background with some opacity
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // A subtle shadow for depth
              borderRadius: '15px', // Rounded corners for the text container
              backdropFilter: 'blur(10px)' // A blur effect on the background (works on browsers that support it)
            }}>
              <h1 style={{
                fontSize: '2.2rem', // Larger font size for the heading for emphasis
                fontWeight: '600', // Makes the font slightly bolder
                marginBottom: '0.5rem', // Adds a bit of margin below the heading
                textTransform: 'uppercase', // Optional: capitalizes the heading for impact
                letterSpacing: '1px' // Spaces out the letters slightly
              }}>Hi, I am Ahmed</h1>
              
              <p className="p-2" style={{
                fontSize: '1rem', // Sets a readable font size for the text
                lineHeight: '1.6', // Increases line height for better readability
                textAlign: 'justify', // Justifies the text for a neat look
                marginBottom: '1rem', // Adds margin to separate paragraphs
              }}>
                As a third-year Computer Science student at the University of Illinois Urbana-Champaign, my journey in software engineering is fueled by a deep-seated passion for technology and innovation. My educational journey at UIUC, combined with my foundational studies at the City Colleges of Chicago, has equipped me with a broad understanding of computer science, from web development and systems design to architecture and machine learning/AI.
              </p>
              
              <p className="p-2" style={{ fontSize: '1rem', lineHeight: '1.6', textAlign: 'justify', marginBottom: '1rem' }}>
                I've dedicated my time to mastering a variety of programming languages and technologies, with a particular focus on web development tools and frameworks like React Native, and backend technologies such as Node.js. My commitment extends beyond the classroom, actively engaging in hands-on projects and research that apply these areas, especially in the realms of machine learning and artificial intelligence.
              </p>
              
              <p className="p-2" style={{ fontSize: '1rem', lineHeight: '1.6', textAlign: 'justify', marginBottom: '1rem' }}>
                My approach to software engineering is characterized by a relentless pursuit of knowledge, an eagerness to face and overcome challenges, and a constant drive to innovate. These experiences have not only refined my technical skills but have also honed my ability to think critically and devise inventive solutions to complex problems.
              </p>
            </div>
          </div>
    )


}