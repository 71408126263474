import React from "react";
import { useParams } from "react-router-dom";
import  GitBasicsArticle  from "./git-basics";
import ReactHooksBasicsArticle from './React-hooks-basics';
import {ReachOutBox} from './../Reach_ou_box';
import { Footer } from './../footer';
import { Menubar } from './../Menu_bar';
function ArticleNotFound() {
  return (
      <div
          className="flex flex-col items-center justify-center h-96 w-96 rounded-lg shadow-lg p-5 m-5"
          style={{
              backgroundColor: '#F7F3F0', 
              boxShadow: '0 8px 24px 0 rgba(0, 0, 0, 0.12)',
              border: '1px solid #EAE7E2',
          }}
      >
          <h1 style={{ color: '#333', fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>Article Not Found</h1>
          <p style={{ color: '#555', fontSize: '1rem', marginBottom: '1rem' }}>Sorry for the inconvenience.</p>
          <p style={{ color: '#777', fontSize: '1rem',position:'relative',top:'-20px' }}>Please check out other articles.</p>
          
      </div>
  );
}
const IDs = {'git-basics': <GitBasicsArticle />,
             'react-hooks': <ReactHooksBasicsArticle />,
};
export function BLOGS() {
    let { id } = useParams();
    function getArticle(id) {
        const article = IDs[id];
        if (article) {
            return article;
        }
        return <ArticleNotFound/>;
    }
  return (
    <div >
    <Menubar />
    <div className="w-full flex"
    style={{ placeContent: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    justifySelf: 'center',
    paddingBottom: '30px',
  }}
    >
    {getArticle(id)}
    </div>
  <div className='flex flex-col items-center h-full pt-5' style={{backgroundColor: '#E9E1D6'}}
  >
  <ReachOutBox/>
  <Footer/>
</div>
</div>
  );
}