import React from 'react';
// /import 'tailwindcss/tailwind.css'; // Make sure Tailwind CSS is correctly imported

const GitBasicsArticle = () => (
    <article className="max-w-4xl mx-auto p-5 my-10 bg-white shadow-lg rounded-lg font-['Roboto'] ">
    <h1 className="text-4xl text-center font-bold my-5">Mastering the Basics of Git</h1>

    <section className="my-8">
      <h2 className="text-2xl font-bold mb-3">Why Git is Essential</h2>
      <p>
        Git isn't just a tool for developers to track code changes; it's a fundamental part of modern software development practices. With Git, teams can work on the same project simultaneously without overwriting each other's work, enabling a more collaborative and efficient workflow.
      </p>
      <p>
        Beyond version control, Git also allows for feature branching and experimenting, making it easier to innovate and iterate on software projects.
      </p>
    </section>

    <section className="my-8">
      <h2 className="text-2xl font-bold mb-3">Setting Up and Initializing a Repository</h2>
      <p>Starting with Git requires setting up a repository, which serves as the foundation for project tracking. Here's how to do it:</p>
      <div className="bg-gray-100 text-gray-800 font-mono rounded p-4 mt-2">
        {/* Git init command */}
        git init
      </div>
      <p>This command creates a new .git directory in your project, which Git uses to track changes. It's the first step in version controlling your project.</p>
    </section>

    <section className="my-8">
      <h2 className="text-2xl font-bold mb-3">Key Git Commands Explained</h2>
      <p>Understanding the basic commands in Git can significantly improve your workflow:</p>
      {/* Example commands */}
      <div className="bg-gray-100 text-gray-800 font-mono rounded p-4 mt-2">
        {/* Git add command */}
        git add &lt;file_name&gt; // Stages specific file<br />
        git commit -m "Your commit message" // Commits your staged files with a message<br />
        git status // Shows the status of changes
      </div>
      <p>These commands help you manage your changes and prepare them for a commit, which is essentially a snapshot of your project at a point in time.</p>
    </section>

    <section className="my-8">
      <h2 className="text-2xl font-bold mb-3">Branching and Merging with Examples</h2>
      <p>Branching lets you diverge from the main line of development to work on new features or fixes independently. Here’s how you create and switch to a new branch:</p>
      {/* Branching example */}
      <div className="bg-gray-100 text-gray-800 font-mono rounded p-4 mt-2">
        git branch feature_x // Creates a new branch called 'feature_x'<br />
        git checkout feature_x // Switches to the 'feature_x' branch
      </div>
      <p>Merging brings these independent lines of development back into the main branch:</p>
      {/* Merging example */}
      <div className="bg-gray-100 text-gray-800 font-mono rounded p-4 mt-2">
        git checkout main // Switch back to the main branch<br />
        git merge feature_x // Merges 'feature_x' branch into 'main'
      </div>
    </section>

    <section className="my-8">
      <h2 className="text-2xl font-bold mb-3">Solving Common Git Issues</h2>
      <p>Encountering issues in Git is common, especially for beginners. Here are solutions to some typical problems:</p>
      <ul className="list-disc pl-5">
        <li>
          <strong>Merge Conflicts:</strong> This happens when Git can’t automatically resolve changes in different branches. Use <code>git mergetool</code> to manually resolve these conflicts.
        </li>
        <li>
          <strong>Lost Commits:</strong> If you accidentally lose commits, you can recover them using <code>git reflog</code> and then checking out the lost commit.
        </li>
      </ul>
    </section>

    <section className="my-8">
      <h2 className="text-2xl font-bold mb-3">Leveraging Git in Collaborative Environments</h2>
      <p>
        Git shines in collaborative settings. With features like pull requests and code reviews, teams can work together more seamlessly, ensuring code quality and consistency. Embrace Git workflows, and you'll find that managing and contributing to projects becomes a much more organized and enjoyable experience.
      </p>
    </section>

    <section className="my-8">
      <h2 className="text-2xl font-bold mb-3">Conclusion</h2>
      <p>
        Mastering Git is a journey that pays off by making your software development process more efficient and collaborative. As you grow more comfortable with Git, you'll discover even more ways it can improve your workflow and enhance your projects.
      </p>
    </section>
  </article>


);

export default GitBasicsArticle;
