import React,{useState} from 'react';
import './App.css'; 
import {FormPopUp} from './form_popUp';

export const ReachOutBox = () => {

  let [show,setShow] = useState(false);
  const handleClose = () => setShow(false);
  

  const handleClick = () => {
    setShow(!show);
    
   
  };

  return (
    <div className=" relative project-container bg-body-color  p-5 h-fit flex flex-col items-center justify-center z-10  box-border border-4 border-white rounded-3xl w-3/5"
    style={{top:'-50px'}}>
      <div className=' text-center project-headers-container' style={{width:'440px'}}>
      <h1 style={{fontSize:'30px' ,color:'white'}} className="pb-1 project-headers">Start A Project</h1>
      <h1 style={{fontSize:'20px' ,color:'white'}} className="pb-2 project-headers" >Interested in working together? We should queue up a time to chat. I’ll buy the coffee.</h1>
      </div>
      <button onClick={handleClick} className="project-button">Let's do this</button>
      <div className='absolute'>
      {show && <FormPopUp handleClose={handleClose} show={show}></FormPopUp>}
      </div>
      
    </div>
  );
};


