import { Menubar } from './Menu_bar';
import {Projects} from './projects';
import {MyCard} from './card';
import {Technology} from './Technology';    
import './App.css';
import {AboutMe} from './aboutMe';
import {ReachOutBox} from './Reach_ou_box';
import { Footer } from './footer';

export function LandingPage(){
    return (
        <div className='flex flex-col h-fit '>
            <Menubar />
            <MyCard/>
            <AboutMe/>
            <Technology/>
            <Projects/>
            <div className='flex flex-col items-center h-full' style={{backgroundColor: '#E9E1D6'}}>
                <ReachOutBox/>
                <Footer/>
            </div>
        </div>
    );
}