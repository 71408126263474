import CloseIcon from '@mui/icons-material/Close';
import { Icon } from '@mui/material';
import vid from './bg2.mp4';
import Alert from '@mui/material/Alert';
import React,{useState,useRef} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import emailjs from '@emailjs/browser';
import './App.css';
export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}
const Confirm = () => {
    return (
      <Alert severity="success" className='h-24 w-48 my-10' >
       
        Message Sent
      </Alert>
    );
  };


export const FormPopUp = (props) => {
    const {handleClose, show} = props;
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    const [copied, setCopied] = useState(false);
    let [loader , setLoader] = useState(false);
    const copyToClipboard = (text) => {
        
        
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
            
        }, 2000);
        
        setTimeout(() => {
           
          setCopied(true);
        }, 2000); 

        setTimeout(() => {
            setCopied(false);
          }, 4000);
        
        
      };
      const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_wo2o8pf', 'template_q15z7zd', form.current, {
        publicKey: '8oC2mQnDUs64IJ5rf',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      
  };
    return (
        <div className={`${showHideClassName} vid_cont bg-white absolute flex flex-col items-center z-20 big-box`} 
        style={{
          top: '-150px',
          left: 'calc(-50vw + 250px)',
          width: 'calc(100vw - 500px)',
          height: "550px",
        }}
        >
      
        <video className='video' autoPlay loop muted playsinline='true' disablePictureInPicture="true" style={{ opacity: 0.8,
          position: 'absolute',
          width: 'calc(100vw - 100px)',
          height: '100%',
          objectFit: 'cover',  
        }}>
          <source src={vid} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
        <div className='w-full flex flex-row justify-end absolute 'style={{top:'-6px',right:'2px'}}><button><Icon sx={{ height: '3rem', right:'120px',top:'50px' }} className='' onClick={handleClose}><CloseIcon /></Icon></button></div>

        <form className="z-40" ref={form} onSubmit={sendEmail}>
        <div className="flex pt-8 flex-col items-center justify-between z-10" style={{ fontSize: '20px', textShadow: '2px 2px 4px rgba(0,0,0,0.1)', fontWeight: 'bold' }}>
          <div className='flex flex-col  px-4 '>
            <div className="flex flex-col items-center  px-2 ">
              <label htmlFor="name" className="text-black">Name</label>
              <input type="text" id="name" name="user_name" className="h-10 border-2 border-black rounded-lg" />
            </div>
            <div className="flex flex-col items-center  px-5 mt-4 ">
              <label htmlFor="email" className="text-black">Email</label>
              <input type="text" id="email" name="user_email" className=" h-10 border-2 border-black rounded-lg" />
            </div>
          </div>
          <div className="flex flex-col items-center pt-5  px-4">
            <label htmlFor="message" className="text-black">Message</label>
            <textarea id="message" name="message" className="h-40 border-2 border-black rounded-lg " />
          </div>
          <button type='submit' className="  w-80 h-10 bg-blue-500 text-white rounded-lg mt-5" onClick={() => { copyToClipboard("Info.instagram"); }}>Submit</button>
        </div>
      </form>
      {loader && <CircularProgress />}
      {copied && <Confirm />}
      </div>
    );
  }
