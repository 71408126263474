import me from './Me.png'
import comps from './comps.png'
export function MyCard(){
    return(
        <div className='flex flex-col justify-center items-center '>
            <div className=' mt-20 py-10  px-10 card-back ml-10 mr-10 h-96 w-100 '>
            <div class="flex  flex-col mx-auto h-full rounded-xl  space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6 justify-center items-center ">
                <h1 style={{fontSize:'35px' ,color:'white'}} className="pb-1">Student, Web Developer & ML enthusiast</h1>
                <h1 style={{fontSize:'20px' ,color:'white'}} className="pb-2" >Just a weirdo who loves coffee</h1>
                <img class=" bg-container block mx-auto h-48 rounded-full sm:mx-0 sm:shrink-0 border border-1" src={me} alt="Woman's Face" />
            </div>
        </div>
        <img src= {comps} alt="computers" className='h-full'/>

        </div>
    )
}