
import developer from './developer.png'
import ml from './ML.png'
import Tutor from './Tutor.png'
export function Technology(){  



    return(

        <div className="flex flex-row p-5 items-center justify-center z-10 Technology-big-container " style={{position:'relative', top:'-100px'}}>
    <div className="flex flex-row Technology-small-container h-fit p-5 mb-20 pb-10 bg-body-color items-center justify-center z-10 divide-x  divide-white box-border border-4 border-white rounded-3xl w-full md:w-3/5"
    style={{paddingBottom:'5rem'}}>
        <div className="p-2 box1 h-fit md:h-fit w-full md:w-1/3 bg-transparent flex flex-col items-center justify-center text-white mb-10 md:mb-0">
            <img src={developer} alt="developer" className="w-32 mt-5"/>
            <h1 className='text-2xl pb-5'>Full Stack Developer</h1>
            <p className='text-center'>I like to build solutions to my problems, automation and building programs from scratch.</p>
            <h1 className='text-xl pb-5 pt-3'>Languages I Use</h1>
            <div className='flex flex-col justify-center text-center'>
                <p>Python</p>
                <p>JavaScript</p>
                <p>Java</p>
                <p>C++</p>
                <p>HTML</p>
                <p>CSS</p>
            </div>
        </div>
        <div className="p-2 h-auto box1 md:h-96 w-full md:w-1/3 bg-transparent flex flex-col items-center justify-center text-white mb-10 md:mb-0">
            <img src={ml} alt="developer" className="w-32 mt-5"/>
            <h1 className='text-2xl pb-5 text-center'>Machine Learning Developer</h1>
            <p className='text-center'>I have recently built an interest in Machine Learning and have been working towards automation using ML.</p>
            <h1 className='text-xl pb-5 pt-3'>Technologies I Use</h1>
            <div className='flex flex-col justify-center text-center'>
                <p>Tensorflow</p>
                <p>PyTorch</p>
                <p>Scikit-Learn</p>
                <p>OpenCV</p>
                <p>Numpy</p>
                <p>Pandas</p>
            </div>
        </div>
        <div className="p-2 h-auto md:h-96 w-full md:w-1/3 bg-transparent flex flex-col items-center justify-center text-white pb-20">
            <img src={Tutor} alt="developer" className="w-32 mt-5"/>
            <h1 className='text-2xl pb-5'>Tutor</h1>
            <p className='text-center'>I have helped numerous engineering students. It makes me happy when I can ignite that Eureka moment in those students.</p>
            <h1 className='text-xl pb-5 pt-3'>Subjects I Tutor</h1>
            <div className='flex flex-col justify-center text-center'>
                <p>Calculus</p>
                <p>Computer Science</p>
                <p>Chemistry</p>
            </div>
        </div>
    </div>
</div>

    )



}