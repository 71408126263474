import React from 'react';
import './App.css'; // Make sure to create this CSS file
const SkillsSection = ({ title, skills }) => (
  <div className="skills-section">
    <h3>{title}</h3>
    <div className="skills-list">
      {skills.map(skill => (
        <span key={skill} className="skill">
          {skill}
        </span>
      ))}
    </div>
  </div>
);


const skillsData = [
  {
    title: 'Frontend',
    skills: ['HTML', 'CSS', 'Tailwind', 'Javascript', 'Typescript', 'React Js',]
  },
  {
    title: 'Backend',
    skills: ['Node Js', 'Flask', 'AWS', 'Firerbase']
  },
  {
    title: 'Database',
    skills: ['MySql', 'MongoDB', 'PostgreSQL', 'Firebase']
  },
  {
    title: 'ML/AI',
    skills: ['Python', 'Tensorflow', 'Keras', 'Scikit-learn', 'Pandas']
  },
  {
    title: 'Others',
    skills: ['Linux', 'Git', 'GPIO', 'Raspberry Pi', 'Arduino']
  },
];

const Skills = () => (
  <div className="skills-container ">
    <h1 className='text-center pb-5' style={{fontSize:'35px' ,color:'black'}}>Additional Technical Expertise</h1>
    <div className="skills-grid">
      {skillsData.map((section) => (
        <SkillsSection key={section.title} title={section.title} skills={section.skills} />
      ))}
    </div>
  </div>
);

export default Skills;

